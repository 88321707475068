<template>
  <div class="card">
    <Button class="p-button-text pi pi-chevron-left" @click="$router.go(-1)">返回</Button>
    <DataTable :value="list" class="p-mt-2"
               :auto-layout="true"
               :paginator="true" :rows="10"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录">

      <Column field="userName" header="名字"></Column>
      <Column field="phone" header="手机号"></Column>
      <Column field="state" header="审核结果">
        <template #body="row">
          <span v-if="row.data.state===1" class="p-tag p-tag-success">通过</span>
          <span v-if="row.data.state===0" class="p-tag p-tag-info">未审核</span>
          <span v-if="row.data.state===2" class="p-tag p-tag-danger">拒绝</span>
        </template>
      </Column>
      <Column field="pic" header="头像">
        <template #body="a">
          <img style="width: 100px;height: 100px" :src="$global_msg.imgurl+a.data.pic"/>
        </template>
      </Column>
      <Column field="degree" header="学历"></Column>
      <Column field="resume" header="简历">
        <template #body="a">
          <a v-if="a.data.resume" :href="$global_msg.imgurl+a.data.resume">点击下载</a>
        </template>
      </Column>
      <Column field="time" header="时间">
        <template #body="a">
          {{ new Date(a.data.time).toLocaleString() }}
        </template>
      </Column>
      <Column header="操作">
        <template #body="a">
          <Button v-if="a.data.state===0||a.data.state===2" @click="setState(a.data.id,1)" class="p-button-sm p-mr-2">
            审核通过
          </Button>
          <Button v-if="a.data.state===0||a.data.state===1" @click="setState(a.data.id,2)" class="p-button-sm">不予通过
          </Button>
        </template>
      </Column>
    </DataTable>
    <Toast/>
  </div>
</template>

<script>
export default {
  name: "RecruitUserList",
  mounted() {
    this.getData()
  },
  data() {
    return {
      url: this.$global_msg.imgurl,
      list: [],
      recruitId: null
    }
  },
  methods: {
    getData() {
      let id = this.$route.query.id
      this.recruitId = id
      this.$http.get('/companies/getUserList', {params: {id: id}}).then((res) => {
        this.list = res.data
        console.log(res.data)
      })
    },
    setState(userId, state) {
      this.$http.post('/companies/setUserRecruitState/' + this.recruitId + '/' + userId + '/' + state).then(() => {
        this.$toast.add({severity: 'success', summary: '审核成功', life: 3000})
        this.getData()
      })
    }
  }
}
</script>

<style scoped>

</style>
